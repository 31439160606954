import React, { useState } from "react";
import { Link } from "gatsby";
import { Button } from "../../style/Button";
import { Flex } from "../../style/Grid";
import { PhotovoltaicsPriceOffer } from "./photovoltaicsPriceOffer";

export const PhotovoltaicsAccountLeftBlock = ({
  title,
  leftButtonTitle,
  leftButtonLink,
  rightButtonTitle,
  rightButtonLink,
  hint,
  priceLeftPanelOffers,
  priceRightPanelOffers,
  priceOffersLeftPanelTitle,
  priceOffersRightPanelTitle,
}) =>
{
  const [leftSelected, setLeftSelected] = useState(0);
  const [rightSelected, setRightSelected] = useState(0);

  const showLeftButton = !!leftButtonTitle;
  const showRightButton = !!rightButtonTitle;
  const leftOfferCode = priceLeftPanelOffers[leftSelected].offerCode;
  const rightOfferCode = priceRightPanelOffers[rightSelected].offerCode;

  return (
    <div className="px-0 sm:px-7">
      <h3 className="text-exploratory-green-400 text-center mt-6 text-H3">{title}</h3>

      <div className="flex gap-px flex-col sm:flex-row">
        <PhotovoltaicsPriceOffer
          title={priceOffersLeftPanelTitle}
          offers={priceLeftPanelOffers}
          icon={require("../../images/photovoltaics-account-excess-icon.png").default}
          iconWidth={62.5}
          background={"resource-green-100"}
          selected={leftSelected}
          setSelected={setLeftSelected}
          priceHintBold
        />
        <PhotovoltaicsPriceOffer
          title={priceOffersRightPanelTitle}
          offers={priceRightPanelOffers}
          icon={require("../../images/electricity.png").default}
          iconWidth={34}
          background={"photovoltaicsDarkGreen"}
          selected={rightSelected}
          setSelected={setRightSelected}
        />
      </div>
      <Flex alignItems="center" mt="4" justifyContent="center" flexDirection={"row"}>
        {(showLeftButton && (
          <Button
            height={50}
            variant={"primary"}
            as={Link}
            to={`${leftButtonLink || ""}${leftOfferCode && `?product=${leftOfferCode}`}`}
            fontSize={3}
            mx={"auto"}
            minWidth={"220px"}
          >
            {leftButtonTitle}
          </Button>
        )) ||
          null}
        {(showRightButton && (
          <Button
            height={50}
            variant={"primary"}
            as={Link}
            to={`${rightButtonLink || ""}${rightOfferCode && `?product=${rightOfferCode}`}`}
            fontSize={3}
            mx={"auto"}
            minWidth={"220px"}
          >
            {rightButtonTitle}
          </Button>
        )) ||
          null}
      </Flex>
      <div className="mt-6 mb-2 text-center text-textGrey text-small">{hint}</div>
    </div>
  );
};
