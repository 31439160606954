import React from "react";
import
{
    PriceSplitTable,
    PriceSplitTableHeader,
    PriceSplitTableInfo,
    PhotovoltaicsDealRow,
    PhotovoltaicsTableText,
} from "./styles/photovoltaics-price-display-styles";
import { MarkupToHtml } from "../../helpers/parse";


export const PRICE_DISPLAY_MAIN_CLASS = "font-bold text-bigForms";

export const PhotovoltaicsPriceDisplay = ({
    price,
    priceOffersSplit = undefined,
    backgroundClass = undefined,
}) =>
{
    const priceJSX = (
        <div className={PRICE_DISPLAY_MAIN_CLASS}>{price}</div>
    );

    if (!priceOffersSplit)
    {
        return priceJSX;
    }


    const { priceTextFrom, priceTextTo, priceSplit } = priceOffersSplit;

    return (
        <PriceSplitTable>
            <PriceSplitTableHeader className={backgroundClass}>
                <MarkupToHtml>
                    {priceTextFrom}
                </MarkupToHtml>
            </PriceSplitTableHeader>

            <PriceSplitTableHeader className={backgroundClass} $secondColumn>
                <MarkupToHtml>
                    {priceTextTo}
                </MarkupToHtml>
            </PriceSplitTableHeader>

            <PriceSplitTableInfo className={backgroundClass}>
                <PhotovoltaicsTableText $mainPrice>
                    {price}
                </PhotovoltaicsTableText>
            </PriceSplitTableInfo>

            <PriceSplitTableInfo className={backgroundClass} $secondColumn>
                {priceSplit.map((price) => (
                    <PhotovoltaicsDealRow key={price.id}>
                        <PhotovoltaicsTableText>
                            <MarkupToHtml>
                                {price.title}
                            </MarkupToHtml>
                        </PhotovoltaicsTableText>
                        <PhotovoltaicsTableText>
                            <MarkupToHtml>
                                {price.price}
                            </MarkupToHtml>
                        </PhotovoltaicsTableText>
                    </PhotovoltaicsDealRow>
                ))}
            </PriceSplitTableInfo>
        </PriceSplitTable>
    )
}