import { useEffect, useState, useRef } from 'react';

const MONTHS_IN_YEAR = 12;
const MEGAWATT_HOURS_IN_KILOWATT_PEAK = 0.95;
const MAX_INPUT_VALUE = 1000;
const MIN_INPUT_VALUE = 0;
const MAX_DECIMAL_PLACES = 3;
export const MAX_PHOTOVOLTAICS_POWER = 50;
const PHOTOVOLTAICS_POWER_EXCEEDED_ERROR_MESSAGE = 'Solární účet lze sjednat pouze do výkonu 50 kWp';

/**
 * annualElectricityExcess se bere do této sazby s plus
 * cenou a všechen excess nad tuto hodnotu se bere do sazby základní
 */
const PLUS_EXCESS_LIMIT = 2;

const calculateExpectedAnnualEarnings = (annualElectricityExcess, solarAccountEarningsPrice, monthlyFee, solarAccountEarningsPricePlus) => {

  // do limitu máme plus
  const excessForPlusPrice = Math.min(annualElectricityExcess, PLUS_EXCESS_LIMIT);

  // nad limit máme základ
  const excessForBasicPrice = Math.max(annualElectricityExcess - PLUS_EXCESS_LIMIT, 0);


  const plusFullPrice = excessForPlusPrice * solarAccountEarningsPricePlus;
  const basicFullPrice = excessForBasicPrice * solarAccountEarningsPrice;
  const fullPrice = plusFullPrice + basicFullPrice;

  const value = fullPrice - MONTHS_IN_YEAR * monthlyFee;

  if (value < MIN_INPUT_VALUE) {
    return MIN_INPUT_VALUE;
  }

  return value;
}
  
const getInputValueFromEvent = (event) => {
  const value = event.target.value;
  if (value === '') {
    return null;
  }
  const restrictedValue = Math.min(Math.max(value, MIN_INPUT_VALUE), MAX_INPUT_VALUE);

  return Number(restrictedValue.toFixed(MAX_DECIMAL_PLACES));
}

export const useSolarCalculatorForm = (solarAccountEarningsPrice, monthlyFee, solarAccountEarningsPricePlus) => {
    const [photovoltaicsPower, setPhotovoltaicsPower] = useState(null);
    const [annualElectricityProductionEstimate, setAnnualElectricityProductionEstimate] = useState(null);
    const [annualElectricityConsumption, setAnnualElectricityConsumption] = useState(null);
    const [annualElectricityExcess, setAnnualElectricityExcess] = useState(null);
    const [expectedAnnualEarnings, setExpectedAnnualEarnings] = useState(0);
    
    const [photovoltaicsPowerErrorMessage, setPhotovoltaicsPowerErrorMessage] = useState('');

    const hasUserEditExcessInputRef = useRef(false);

    useEffect(() => {
      if (photovoltaicsPowerErrorMessage) {
        return;
      }

      if (photovoltaicsPower === null) {
        return;
      }

      const annualElectricityProductionEstimate = photovoltaicsPower * MEGAWATT_HOURS_IN_KILOWATT_PEAK;
      setAnnualElectricityProductionEstimate(Number(annualElectricityProductionEstimate.toFixed(MAX_DECIMAL_PLACES)));
    }, [photovoltaicsPower, photovoltaicsPowerErrorMessage]);

    useEffect(() => {
      const hasUserManuallyEditInput = hasUserEditExcessInputRef && hasUserEditExcessInputRef.current;
      if (hasUserManuallyEditInput || (annualElectricityProductionEstimate === null && annualElectricityConsumption === null)) {
        return;
      }

      const annualElectricityExcess = annualElectricityProductionEstimate - annualElectricityConsumption;
      if (annualElectricityExcess < MIN_INPUT_VALUE) {
        setAnnualElectricityExcess(MIN_INPUT_VALUE);
      } else {
        setAnnualElectricityExcess(Number(annualElectricityExcess.toFixed(MAX_DECIMAL_PLACES)));
      }
    
    }, [annualElectricityProductionEstimate, annualElectricityConsumption]);
  
    useEffect(() => {
      const annualEarnings = calculateExpectedAnnualEarnings(annualElectricityExcess, solarAccountEarningsPrice, monthlyFee, solarAccountEarningsPricePlus);
      setExpectedAnnualEarnings(Math.round(annualEarnings));
    }, [annualElectricityExcess, solarAccountEarningsPrice, monthlyFee, solarAccountEarningsPricePlus]);
  
    const handlePhotovoltaicsPowerChange = (event) => {
      const value = getInputValueFromEvent(event);

      if ((value >= MAX_PHOTOVOLTAICS_POWER) && !photovoltaicsPowerErrorMessage) {
        setPhotovoltaicsPowerErrorMessage(PHOTOVOLTAICS_POWER_EXCEEDED_ERROR_MESSAGE);
      } else if ((value < MAX_PHOTOVOLTAICS_POWER) && photovoltaicsPowerErrorMessage) {
        setPhotovoltaicsPowerErrorMessage('');
      }

      setPhotovoltaicsPower(value);
    }
  
    const handleAnnualElectricityConsumption = (event) => {
      const value = getInputValueFromEvent(event);
  
      setAnnualElectricityConsumption(value);
    }
  
    const handleAnnualElectricityExcess = (event) => {
      const value = getInputValueFromEvent(event);

      if (hasUserEditExcessInputRef && !hasUserEditExcessInputRef.current) {
        hasUserEditExcessInputRef.current = true;
      }
  
      setAnnualElectricityExcess(value);
    }

    const hasUserEditAnyInput =
      photovoltaicsPower !== null ||
      annualElectricityProductionEstimate !== null ||
      annualElectricityConsumption !== null ||
      annualElectricityExcess !== null;

    return {
      expectedAnnualEarnings,
      photovoltaicsPower,
      annualElectricityProductionEstimate,
      annualElectricityConsumption,
      annualElectricityExcess,
      handlePhotovoltaicsPowerChange,
      handleAnnualElectricityConsumption,
      handleAnnualElectricityExcess,
      maxPhotovoltaicsPower: MAX_PHOTOVOLTAICS_POWER,
      photovoltaicsPowerErrorMessage,
      hasUserEditAnyInput,
    };
}