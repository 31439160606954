import React, { useState } from "react";
import CheckIcon from "../../style/icons/check";
import { SwitchToggle } from "../../style/SwitchToggle";
import { Modal } from "../Modal";
import { ArrowSmDownIcon, XIcon } from "@heroicons/react/solid";
import { MarkupToHtml } from "../../helpers/parse";
import { PhotovoltaicsPriceDisplay, PRICE_DISPLAY_MAIN_CLASS } from "./photovolaticsPriceDisplay";

const CHECK_ICON_SIZE = 12;
const OFFER_ICON_HEIGHT = 40;

const callbackOrDownload = (download, callback) =>
{
  if (window?.innerWidth < 500)
  {
    window.location.href = download;
  } else
  {
    callback();
  }
};

export const PhotovoltaicsPriceOffer = ({
  title,
  offers,
  icon,
  iconWidth,
  background,
  selected,
  setSelected,
  priceHintBold = false,
}) =>
{
  const [modalOpen, setModalOpen] = useState(false);
  const selectedOffer = offers[selected];
  const isOnlyOneOffer = offers.length === 1;

  const backgroundClass = background ? `bg-${background}` : "";
  const priceOffersSplit = selectedOffer?.priceOffersSplit?.data?.attributes ?? undefined;

  const advantagesAdditionalClasses = priceOffersSplit ? "xl:px-[20px]" : ``;

  const priceHintClass = priceHintBold ? PRICE_DISPLAY_MAIN_CLASS : "text-small inline-block";

  return (
    <div className={`p-6 px-[12px] ${backgroundClass} flex-1 flex flex-col`}>
      <div className="flex gap-2 justify-center	items-center pb-4">
        <img className="mb-0 object-contain" src={icon} width={iconWidth} height={OFFER_ICON_HEIGHT} alt="Solární účet" />
        <div className="text-exploratory-green-400 font-bold text-bigForms">{title}</div>
      </div>
      <div className="text-center pb-4">
        <SwitchToggle
          backgroundColor="#fff"
          offersLength={offers.length}
          selected={!isOnlyOneOffer && selected}
          onClick={isOnlyOneOffer ? undefined : () => setSelected(s => Number(!s))}
          textColor={"green"}
        >
          <div>
            {offers.map((offer, index) => (
              <span key={`offer-option-${index}`} className={!isOnlyOneOffer && selected === index ? "active" : ""}>
                {offer.title}
              </span>
            ))}
          </div>
        </SwitchToggle>
      </div>
      <div className="text-center text-exploratory-green-400 pb-4">
        <PhotovoltaicsPriceDisplay
          price={selectedOffer.price}
          priceOffersSplit={priceOffersSplit}
          backgroundClass={backgroundClass}
        />

        <div className={priceHintClass}>{selectedOffer.priceHint}</div>
      </div>

      {(selectedOffer.advantages) && (
        <div className={`flex justify-center flex-1 pb-4 ${advantagesAdditionalClasses}`}>
          <div>
            <MarkupToHtml children={selectedOffer.advantages} components={{
              ul: ({ children }) => <>{children}</>,
              li: ({ children }) => <div className="flex gap-3 text-exploratory-green-400 text-small">
                <div className="mt-0.5">
                  <CheckIcon width={CHECK_ICON_SIZE} height={CHECK_ICON_SIZE} />
                </div>
                <div>
                  {children}
                </div>
              </div>
            }} />
          </div>
        </div>
      )}

      <div className="flex gap-2 justify-center items-center">
        <img className="mb-0" src={require("../../images/download.png").default} width={20} height={20} alt="Stáhnout" />
        <a
          className="text-exploratory-green-400 text-underlined cursor-pointer"
          href={'#'}
          onClick={(e) =>
          {
            e.preventDefault();
            e.stopPropagation();

            callbackOrDownload(selectedOffer.priceOfferLink, () => setModalOpen(true))
          }}>
          {selectedOffer.priceOfferText}
        </a>
      </div>

      <Modal onClose={setModalOpen} show={modalOpen}>
        <div className="inline-block align-bottom bg-white px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-screen-lg w-full sm:p-6">
          <div className="flex justify-end leading-none">
            <button type="button" className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none" onClick={() => setModalOpen(false)}>
              <span className="sr-only">Zavřít</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          <div className={"mt-4"}>
            <iframe src={selectedOffer.priceOfferLink} frameBorder="0" title={'pdf'} className={"w-full"} style={{ height: "70vh" }} />
          </div>
          <div className="flex flex-col-reverse sm:flex-row mt-4 items-center sm:justify-end">
            <a
              href={selectedOffer.priceOfferLink}
              target="_blank"
              className={"flex items-center font-bold text-exploratory-green-400 sm:mr-8 mt-4 sm:mt-0 hover:no-underline"}
              data-analytics="download"
              data-type="Pricelist"
              data-area="TopOffer"
              data-format=".pdf"
              download
            >
              <ArrowSmDownIcon className="h-6 w-6 mr-2" aria-hidden="true" /> Stáhnout ceník
            </a>
          </div>
        </div>
      </Modal>
    </div>
  );
};
