import styled, { css } from 'styled-components';



export const PriceSplitTable = styled.div`
    display: grid;

    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;

    // # vnitřní border
    background-color: ${props => props.theme.colors.white[100]};
    gap: 1px;
`;

const TableXPadding = css`
    padding: 0px 6px;

    ${props => props.$secondColumn ? `
        padding: 0px 8px;   
        padding-right: 4px;
    ` : ``}
`;

export const PriceSplitTableHeader = styled.p`
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;

    ${TableXPadding}
    padding-bottom: 4px;

    margin: 0;
    text-align: left;
`;

export const PriceSplitTableInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${TableXPadding}

    padding-top: 8px;
    padding-bottom: 4px;
`;


export const PhotovoltaicsDealRow = styled.div`
    display: flex;
    justify-content: space-between;

    @media (min-width: ${props => props.theme.breakpoints.xs}) and (max-width: ${props => props.theme.breakpoints.lg}) {
        flex-direction: column;
        align-items: start;
    }

    align-items: center;
`;

export const PhotovoltaicsTableText = styled.p`
    margin: 0;
    text-align: left;

    ${props => props.$mainPrice ? `
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
    ` : `
        font-weight: 500;
        font-size: 12px; 
        line-height: 18px;
    `}

`;