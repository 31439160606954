import React from "react";
import { Container } from "../style/Grid";
import { DoubleBlockItem } from "./doubleBlockItem";

export const DoubleBlock = ({
  title,
  firstBlock,
  secondBlock,
  firstBlockFlex,
  secondBlockFlex,


  thirdWholeBlock = undefined
}) =>
{

  return (
    <Container className="mx-auto" px={[0, null, null, null, 0]}>
      {title ? <h2 className="text-exploratory-green-400 text-center w-full text-H3 sm:text-H2 mb-8">{title}</h2> : null}
      <div className="flex gap-1 flex-col md:flex-row flex-wrap">
        <DoubleBlockItem customFlex={firstBlockFlex}>
          {firstBlock}
        </DoubleBlockItem>
        <DoubleBlockItem customFlex={secondBlockFlex}>
          {secondBlock}
        </DoubleBlockItem>


        {thirdWholeBlock ?
          <DoubleBlockItem fullBasis paddingAlt>
            {thirdWholeBlock}
          </DoubleBlockItem>
          : null}
      </div>
    </Container>
  );
}

