import React from "react";

import { SolarPanelIconsWrapper, SolarPanelIconWrapper } from "../../style/Photovoltaics/PhotovoltaicsInfoBlock";

const solarPanelUseIconByItemEnum = {
    'billing': <img src={require('../../images/fve-alt.png').default} className="mb-0" width="62.5px" height="40px" alt="vyúčtování" />,
    'supply_point': <img src={require('../../images/pay-another-supply-point.png').default} className="mb-0" width="75px" height="40px" alt="zálohy" />,
    'voucher': <img src={require('../../images/pay-voucher.png').default} className="mb-0" width="81px" height="40px" alt="poukaz" />,
};


export const PhotovoltaicsSolarUse = ({
    solarPanelUse,
    iconsScrollTargetRef = undefined,

    altSizing = false
}) =>
{
    const clickableDisabled = iconsScrollTargetRef?.current === undefined;

    const handleIconContainerClick = () =>
    {
        iconsScrollTargetRef?.current?.scrollIntoView();
    }


    const altTextClasses = altSizing ? 'leading-[20px]' : '';
    const solarUsageClasses = altSizing ? 'grow-0 md:shrink-0 basis-[180px]' : '';
    const altCompleteWrapper = altSizing ? 'justify-center flex-nowrap md:flex-wrap' : 'flex-wrap mt-6 mb-4';

    return (
        <SolarPanelIconsWrapper className={`flex gap-1 ${altCompleteWrapper}`}>
            {solarPanelUse.map((solarPanelUseItem, index) => (
                <SolarPanelIconWrapper
                    key={index}
                    className={`flex flex-col gap-[12px] flex-1 items-center ${solarUsageClasses}`}
                    clickableDisabled={clickableDisabled}
                    onClick={handleIconContainerClick}
                >
                    {solarPanelUseIconByItemEnum[solarPanelUseItem?.icon]}
                    <div className={`font-bold text-exploratory-green-400 text-center ${altTextClasses}`}>
                        {solarPanelUseItem?.title || ''}
                    </div>
                </SolarPanelIconWrapper>
            ))}
        </SolarPanelIconsWrapper>
    )

}