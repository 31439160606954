import React from "react";
import { PhotovoltaicsSolarUse } from "./photovoltaicsSolarUse";
import { Heading } from "../../style/Heading";




export const SolarUseCompleteBlock = ({
    solarPanelUse,
    iconsScrollTargetRef = undefined,
    heading,
}) =>
{
    return (
        <>
            <Heading as="h2" color="green.dark" fontSize={"22px"} lineHeight={"44px"} mb={"12px"} textAlign="center">
                {heading}
            </Heading>
            <PhotovoltaicsSolarUse
                solarPanelUse={solarPanelUse ?? []}
                iconsScrollTargetRef={iconsScrollTargetRef}
                altSizing
            />
        </>
    )
}