import styled from "styled-components";

export const SolarPanelIconsWrapper = styled.div``;

export const SolarPanelIconWrapper = styled.div`

  ${props =>
  {
    if (props.clickableDisabled)
    {
      return ``;
    }

    return `
      &:hover {
        cursor: pointer;

        & > img {
          filter: brightness(1.2);
        }
      }
    `;
  }}



  
`;
