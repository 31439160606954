import React from "react";


export const DoubleBlockItem = ({ children = undefined, customFlex = undefined, fullBasis = undefined, paddingAlt = undefined }) =>
{
    return (
        <div className={`bg-white ${customFlex ? `flex-${customFlex}` : 'flex-1'}
        ${fullBasis ? `basis-full flex flex-col justify-center` : ``}
        px-5 sm:px-8
        ${paddingAlt ? `
                        pt-[24px]
                        pb-[40px]` : `pt-6 pb-8`}
        `}>
            {children}
        </div>
    )
}